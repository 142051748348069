import { Observable } from 'rxjs';
import {
  CustomerCreateDto,
  CustomerEditDto,
  CustomerGetAllDto,
  CustomerListQueryParamsDto,
  CustomerPageDto,
} from '../dtos/customer.dto';

export abstract class CustomerRepository {
  abstract getPage(
    customer: CustomerListQueryParamsDto
  ): Observable<CustomerPageDto>;
  abstract create(customer: CustomerCreateDto): Observable<any>;
  abstract update(customer: CustomerEditDto): Observable<any>;
  abstract getAll(
    customer?: CustomerListQueryParamsDto
  ): Observable<CustomerGetAllDto>;
  abstract getPageExternal(
    customer: CustomerListQueryParamsDto
  ): Observable<CustomerPageDto>;
}
